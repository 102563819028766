<template>
  <a target="_blank" class="account btn-green-background btn btn-flex" :href="validateUrl('/client/login/')">
    Me connecter
    <img
      :src="getPicto('account-black', 'svg', 'classique')"
      :alt="'Espace personnel'"
      width="26"
      height="26"
      class="icon vertical-align-bottom"
    />
  </a>
</template>

<script>
import UtilMixin from "../../../mixins/UtilMixin";
export default {
  name: "LoginInHeaderModule",
  mixins: [UtilMixin],
  data() {
    return {
      route: "",
      partner: null,
    };
  },
  mounted() {
    // Partnaire loading
    this.partner = this.getPartenaire().subdomain;
    this.route = this.getPartenaire().route;
  },
  methods: {
    validateUrl(url) {
      return url + this.route;
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/sass/modules/_loginInHeaderModule";
</style>
